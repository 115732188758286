import { NgModule, ModuleWithProviders } from '@angular/core';
import { AuthService } from './auth.service';
import { AuthorizationGuard } from './authorization.guard';
import { LoginComponent } from './login/login.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { AuthorizationCanGuard } from './authorization.canload.guard';
import { CommonModule } from '@angular/common';

@NgModule ({
  providers: [
    AuthService,
    FormsModule,
    ReactiveFormsModule,
    AuthorizationGuard,
    AuthorizationCanGuard,
  ],
  imports: [
    ReactiveFormsModule,
    CommonModule
  ],
  declarations: [LoginComponent]
})

  export class AuthModule {  }
