import { Component, OnInit, ViewChild } from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {ApiClientService} from "../../services-generated";
import {Router} from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  erro = "";
  showPassword: boolean = true;
  @ViewChild('snackBar') snackBar;
  mensagemErro = "Usuário ou Senha incorreto";

  constructor(private api: ApiClientService,
              private router: Router,) { }

  ngOnInit() {
    this.form = new FormGroup({
      usuario: new FormControl(''),
      senha: new FormControl('')
    });

  }

  temErro(): boolean {
    return this.erro !== ''
        && this.erro !== null
        && this.erro !== undefined;
  }

  public toogleShowPassword(): void{
    const inputPassword = document.querySelector("#senha");
    const inputImage = document.querySelector("#senha + label > img");
    if (this.showPassword) {
      inputPassword.setAttribute('type', 'text');
      inputImage.setAttribute('src', '../../../assets/images/icons/senha-mostrar.png');
    } else {
      inputPassword.setAttribute('type', 'password');
      inputImage.setAttribute('src', '../../../assets/images/icons/senha-esconder.png');
    }
    this.showPassword = !this.showPassword;
  }

  private showSnackBar():void{
    this.snackBar.nativeElement.classList.add("showSnackBar");
    setTimeout(()=> {
      this.snackBar.nativeElement.classList.remove("showSnackBar");
    }, 2500);
  }

  public removeSnackBar():void {
    this.snackBar.nativeElement.classList.remove("showSnackBar");
  }

  login() {

    if (this.form.invalid) {
      return;
    }

    this.api.ApiUsuariosistemaAuthPost(this.form.value).subscribe(
      res => {
        if (res.body.Authenticated) {
          this.erro = "";
          localStorage.setItem("userToken", res.body.AccessToken);
          localStorage.setItem("userData", JSON.stringify(res.body));
          this.router.navigate(['/home']);
        } else {
          this.showSnackBar();
          this.mensagemErro = res.body.Erro;
          this.erro = res.body.Erro;
        }
      }
    )
  }


}
