
export enum EnumTipoItemPesquisaForms {
  Entry = 'Entry',
  Label = 'Label',
  Switch = 'Switch',
  DropDown = 'DropDown',
  DropDownMult = 'DropDownMult',
  EAN = 'EAN',
  BarCode = 'BarCode',
  Pesquisa = 'Pesquisa',
  PriceEntry = 'PriceEntry',
  EsteiraProduto = 'EsteiraProduto',
  DropDownPesquisa = 'DropDownPesquisa',
  DropDownMultPesquisa = 'DropDownMultPesquisa',
  DropDownFoto = 'DropDownFoto',
  DropDownMultFoto = 'DropDownMultFoto',
}
