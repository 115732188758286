import { Pipe, PipeTransform, Injector } from '@angular/core';

@Pipe({
  name: 'engDynamic',
  pure: false
})
export class EngDynamicPipe implements PipeTransform {

  constructor(private injector: Injector) {

  }

  transform(value: any, pipeToken: any, pipeArgs: any[]): any {
    if (!pipeToken) {
      return value;
    }
    else {
      let pipe = this.injector.get(pipeToken);
      return pipe.transform(value, ...pipeArgs);
    }
  }
}
