export { GenericCollectionResponseAlbumFotosEntity } from './models/genericcollectionresponsealbumfotosentity.model';
export { AlbumFotosEntity } from './models/albumfotosentity.model';
export { FotoVO } from './models/fotovo.model';
export { TemplateDownloadVO } from './models/templatedownloadvo.model';
export { UsuarioSistemaBase } from './models/usuariosistemabase.model';
export { EstabelecimentoBase } from './models/estabelecimentobase.model';
export { RoteiroAlvoProdutoVO } from './models/roteiroalvoprodutovo.model';
export { ArquivoVO } from './models/arquivovo.model';
export { TipoFotoEnum } from './models/tipofotoenum.model';
export { StatusAvaliacaoEnum } from './models/statusavaliacaoenum.model';
export { ResultadoAvaliacaoVO } from './models/resultadoavaliacaovo.model';
export { EnderecoVO } from './models/enderecovo.model';
export { ProdutoFamiliaBase } from './models/produtofamiliabase.model';
export { ProdutoBase } from './models/produtobase.model';
export { CategoriaProdutoBase } from './models/categoriaprodutobase.model';
export { SubCategoriaProdutoBase } from './models/subcategoriaprodutobase.model';
export { MarcaBase } from './models/marcabase.model';
export { SegmentoProdutoBase } from './models/segmentoprodutobase.model';
export { ListarAlbumsPorUsuarioRequest } from './models/listaralbumsporusuariorequest.model';
export { ListarFotosRequest } from './models/listarfotosrequest.model';
export { FotosRemovidasEntity } from './models/fotosremovidasentity.model';
export { AlbumFotosFilaEntity } from './models/albumfotosfilaentity.model';
export { StatusFilaEnum } from './models/statusfilaenum.model';
export { AlbumFotoFilaRequest } from './models/albumfotofilarequest.model';
export { DownloadResponse } from './models/downloadresponse.model';
export { ListAlbumRequest } from './models/listalbumrequest.model';
export { PaginacaoDTO } from './models/paginacaodto.model';
export { SalvarAvaliacaoFotoTOOQRequest } from './models/salvaravaliacaofototooqrequest.model';
export { RespostaPromotorAppFotoRequest } from './models/respostapromotorappfotorequest.model';
export { SalvarAvaliacaoFotoRequest } from './models/salvaravaliacaofotorequest.model';
export { HistoricoAvaliacaoFotosDTO } from './models/historicoavaliacaofotosdto.model';
export { MensagemAvaliacaoFotoVO } from './models/mensagemavaliacaofotovo.model';
export { AlbumFotosModeloDTO } from './models/albumfotosmodelodto.model';
export { UploadFileDTO } from './models/uploadfiledto.model';
export { AlbumFotosRecorrenteEntity } from './models/albumfotosrecorrenteentity.model';
export { FiltroAlbumFotosVo } from './models/filtroalbumfotosvo.model';
export { DayOfWeek } from './models/dayofweek.model';
export { TipoRecorrenciaAlbumFotos } from './models/tiporecorrenciaalbumfotos.model';
export { GenericCollectionResponseAlbumFotosRecorrenteEntity } from './models/genericcollectionresponsealbumfotosrecorrenteentity.model';
export { AtualizacaoCadastralDadoColetadoEntity } from './models/atualizacaocadastraldadocoletadoentity.model';
export { RoteiroDadosPesquisaFormsEntity } from './models/roteirodadospesquisaformsentity.model';
export { TipoTarefaRoteiroEnum } from './models/tipotarefaroteiroenum.model';
export { OrigemDadoColetadoEnum } from './models/origemdadocoletadoenum.model';
export { DadoConcorrente } from './models/dadoconcorrente.model';
export { RespostaFormsVO } from './models/respostaformsvo.model';
export { EnumTipoItemPesquisaForms } from './models/enumtipoitempesquisaforms.model';
export { PickerPesquisaItemVO } from './models/pickerpesquisaitemvo.model';
export { AtualizacaoCadastralPlanejadoEntity } from './models/atualizacaocadastralplanejadoentity.model';
export { PesquisaFormsEntity } from './models/pesquisaformsentity.model';
export { ItemFormsVO } from './models/itemformsvo.model';
export { TipoEntryFormsEnum } from './models/tipoentryformsenum.model';
export { NullableTipoFotoPesquisaFormsEnum } from './models/nullabletipofotopesquisaformsenum.model';
export { PickerItemListActionFoto } from './models/pickeritemlistactionfoto.model';
export { CadastrarAtualizacaoCadastralRequest } from './models/cadastraratualizacaocadastralrequest.model';
export { PesquisaIdObrigatorio } from './models/pesquisaidobrigatorio.model';
export { GenericCollectionResponseListaAtualizacaoCadastralDTO } from './models/genericcollectionresponselistaatualizacaocadastraldto.model';
export { ListaAtualizacaoCadastralDTO } from './models/listaatualizacaocadastraldto.model';
export { ListarAtualizacaoCadastralRequest } from './models/listaratualizacaocadastralrequest.model';
export { GenericCollectionResponseRoteiroDadosPesquisaFormsDTO } from './models/genericcollectionresponseroteirodadospesquisaformsdto.model';
export { RoteiroDadosPesquisaFormsDTO } from './models/roteirodadospesquisaformsdto.model';
export { BandeiraEstabelecimentoEntity } from './models/bandeiraestabelecimentoentity.model';
export { RedeEstabelecimentoBase } from './models/redeestabelecimentobase.model';
export { GenericCollectionResponseBandeiraEstabelecimentoEntity } from './models/genericcollectionresponsebandeiraestabelecimentoentity.model';
export { GenericListRequest } from './models/genericlistrequest.model';
export { NullableStatusUsuarioEnum } from './models/nullablestatususuarioenum.model';
export { ListarPorUfsCidadesRequest } from './models/listarporufscidadesrequest.model';
export { OrcamentoRoteiroAutomaticoRequest } from './models/orcamentoroteiroautomaticorequest.model';
export { BandeiraEstabelecimentoBase } from './models/bandeiraestabelecimentobase.model';
export { RedeEstabelecimentoEntity } from './models/redeestabelecimentoentity.model';
export { KeyValuePairStringString } from './models/keyvaluepairstringstring.model';
export { BandeiraERP } from './models/bandeiraerp.model';
export { LojaERP } from './models/lojaerp.model';
export { SubCanalBase } from './models/subcanalbase.model';
export { UnidadeNegocioProprietarioBase } from './models/unidadenegocioproprietariobase.model';
export { CanalEstabelecimentoEntity } from './models/canalestabelecimentoentity.model';
export { StatusEstabelecimentoEntity } from './models/statusestabelecimentoentity.model';
export { SubstatusEstabelecimentoEntity } from './models/substatusestabelecimentoentity.model';
export { MarcaEntity } from './models/marcaentity.model';
export { SegmentoProdutoEntity } from './models/segmentoprodutoentity.model';
export { UsuarioSistemaEntity } from './models/usuariosistemaentity.model';
export { TelefoneVO } from './models/telefonevo.model';
export { DadosPessoaisVO } from './models/dadospessoaisvo.model';
export { StatusUsuarioEnum } from './models/statususuarioenum.model';
export { DocumentosVO } from './models/documentosvo.model';
export { PausaProgramadaVO } from './models/pausaprogramadavo.model';
export { HorarioDeTrabalho } from './models/horariodetrabalho.model';
export { DispositivoAcessoSistemaEntity } from './models/dispositivoacessosistemaentity.model';
export { RegistroGeralVO } from './models/registrogeralvo.model';
export { CarteiraTrabalhoVO } from './models/carteiratrabalhovo.model';
export { EstadoCivilEnum } from './models/estadocivilenum.model';
export { SexoEnum } from './models/sexoenum.model';
export { NullableTipoLocomocaoEnum } from './models/nullabletipolocomocaoenum.model';
export { ContaBancariaVO } from './models/contabancariavo.model';
export { TipoContaBancariaEnum } from './models/tipocontabancariaenum.model';
export { CartasRequest } from './models/cartasrequest.model';
export { ModeloCartaEntity } from './models/modelocartaentity.model';
export { GenericCollectionResponseModeloCartaEntity } from './models/genericcollectionresponsemodelocartaentity.model';
export { ProdutoBaseComFoto } from './models/produtobasecomfoto.model';
export { ProdutoFamiliaEntity } from './models/produtofamiliaentity.model';
export { ProdutoEntity } from './models/produtoentity.model';
export { ValorMinMax } from './models/valorminmax.model';
export { CategoriaProdutoEntity } from './models/categoriaprodutoentity.model';
export { GenericCollectionResponseCategoriaProdutoEntity } from './models/genericcollectionresponsecategoriaprodutoentity.model';
export { CategoriaProdutoDTO } from './models/categoriaprodutodto.model';
export { ChatMensagemEntity } from './models/chatmensagementity.model';
export { StatusMensagem } from './models/statusmensagem.model';
export { ConfiguracaoMobileEntity } from './models/configuracaomobileentity.model';
export { ConfiguracaoRegistroPontoVO } from './models/configuracaoregistropontovo.model';
export { ConfiguracaoSincronismoMobileVO } from './models/configuracaosincronismomobilevo.model';
export { RegistroSincronismoColecaoVO } from './models/registrosincronismocolecaovo.model';
export { TipoSincronismoEnum } from './models/tiposincronismoenum.model';
export { ConfiguracaoWebDTO } from './models/configuracaowebdto.model';
export { TelasEnum } from './models/telasenum.model';
export { LinkBiVo } from './models/linkbivo.model';
export { ConfigEmailVo } from './models/configemailvo.model';
export { ConfiguracaoUsuarioPerfilAppDTO } from './models/configuracaousuarioperfilappdto.model';
export { ConfiguracaoPontoApp } from './models/configuracaopontoapp.model';
export { ConfigDadosColetadosEntity } from './models/configdadoscoletadosentity.model';
export { TipoConfiguracaoEmpresaEnum } from './models/tipoconfiguracaoempresaenum.model';
export { EmpresaEntity } from './models/empresaentity.model';
export { GenericCollectionResponseEmpresaEntity } from './models/genericcollectionresponseempresaentity.model';
export { UnidadeFederativaEntity } from './models/unidadefederativaentity.model';
export { GenericCollectionResponseEstabelecimentoEntity } from './models/genericcollectionresponseestabelecimentoentity.model';
export { EstabelecimentoEntity } from './models/estabelecimentoentity.model';
export { ListarLojasPorUfRequest } from './models/listarlojasporufrequest.model';
export { ListarEstabelecimentosPorBandeiraEnderecoRequest } from './models/listarestabelecimentosporbandeiraenderecorequest.model';
export { GeocodeResponse } from './models/geocoderesponse.model';
export { Result } from './models/result.model';
export { AddressComponent } from './models/addresscomponent.model';
export { Geometry } from './models/geometry.model';
export { Bounds } from './models/bounds.model';
export { Location } from './models/location.model';
export { Viewport } from './models/viewport.model';
export { Northeast } from './models/northeast.model';
export { Southwest } from './models/southwest.model';
export { GenericCollectionResponseProdutoFamiliaEntity } from './models/genericcollectionresponseprodutofamiliaentity.model';
export { FeriadoEntity } from './models/feriadoentity.model';
export { TipoFeriadoEnum } from './models/tipoferiadoenum.model';
export { TipoDataFeriadoEnum } from './models/tipodataferiadoenum.model';
export { GenericCollectionResponseFeriadoEntity } from './models/genericcollectionresponseferiadoentity.model';
export { GenericCollectionResponseFeriadoDTO } from './models/genericcollectionresponseferiadodto.model';
export { FeriadoDTO } from './models/feriadodto.model';
export { GenericCollectionResponseFormularioDinamicoEntity } from './models/genericcollectionresponseformulariodinamicoentity.model';
export { FormularioDinamicoEntity } from './models/formulariodinamicoentity.model';
export { ModeloJsonVO } from './models/modelojsonvo.model';
export { TipoFormularioEnum } from './models/tipoformularioenum.model';
export { Page } from './models/page.model';
export { Element } from './models/element.model';
export { FormularioDinamicoBase } from './models/formulariodinamicobase.model';
export { GenericRelatorioRequest } from './models/genericrelatoriorequest.model';
export { GenericCollectionResponseHoraPorMarcaDTO } from './models/genericcollectionresponsehorapormarcadto.model';
export { HoraPorMarcaDTO } from './models/horapormarcadto.model';
export { HoraMarcaDTO } from './models/horamarcadto.model';
export { LogDispositivoVO } from './models/logdispositivovo.model';
export { ListarLogsRequest } from './models/listarlogsrequest.model';
export { GenericCollectionResponseLogDispositivoVO } from './models/genericcollectionresponselogdispositivovo.model';
export { LogGeolocalizacaoVO } from './models/loggeolocalizacaovo.model';
export { LogDispositivoEntity } from './models/logdispositivoentity.model';
export { LogGeolocalizacaoEntity } from './models/loggeolocalizacaoentity.model';
export { GenericCollectionResponseMarcaEntity } from './models/genericcollectionresponsemarcaentity.model';
export { MarcaIdChatDTO } from './models/marcaidchatdto.model';
export { ResumoRelatorioPromotor } from './models/resumorelatoriopromotor.model';
export { LiberarDispositivoRequest } from './models/liberardispositivorequest.model';
export { ResumoUsuarioLiberarDispositivoDTO } from './models/resumousuarioliberardispositivodto.model';
export { UsuarioSistemaDTO } from './models/usuariosistemadto.model';
export { DispositivoAcessoDesbloqueioDTO } from './models/dispositivoacessodesbloqueiodto.model';
export { DispositivoDataVO } from './models/dispositivodatavo.model';
export { SuperiorHabilitarDispositivoRequest } from './models/superiorhabilitardispositivorequest.model';
export { NotificacaoEntity } from './models/notificacaoentity.model';
export { TipoNotificacaoEnum } from './models/tiponotificacaoenum.model';
export { NotificacaoResponse } from './models/notificacaoresponse.model';
export { NotificacaoMobileDTO } from './models/notificacaomobiledto.model';
export { TipoNotificacaoMobileEnum } from './models/tiponotificacaomobileenum.model';
export { DataNotificacaoDTO } from './models/datanotificacaodto.model';
export { NotificacaoLayoutEnum } from './models/notificacaolayoutenum.model';
export { CalendarioNotificacaoVO } from './models/calendarionotificacaovo.model';
export { NotificacaoCancelarListarRequest } from './models/notificacaocancelarlistarrequest.model';
export { GenericCollectionResponseNotificacaoMobileCancelarDTO } from './models/genericcollectionresponsenotificacaomobilecancelardto.model';
export { NotificacaoMobileCancelarDTO } from './models/notificacaomobilecancelardto.model';
export { NotificacaoMobileEntity } from './models/notificacaomobileentity.model';
export { DataNotificacaoVO } from './models/datanotificacaovo.model';
export { PagamentoExpressEntity } from './models/pagamentoexpressentity.model';
export { ListarPagExpressRequest } from './models/listarpagexpressrequest.model';
export { GenericCollectionResponsePagamentoExpressEntity } from './models/genericcollectionresponsepagamentoexpressentity.model';
export { GenericCollectionResponsePesquisaFormsEntity } from './models/genericcollectionresponsepesquisaformsentity.model';
export { PesquisaFormsNomeDTO } from './models/pesquisaformsnomedto.model';
export { GenericCollectionResponseProdutoEntity } from './models/genericcollectionresponseprodutoentity.model';
export { UploadFotoProdutoDTO } from './models/uploadfotoprodutodto.model';
export { ProdutoEstabelecimentoEntity } from './models/produtoestabelecimentoentity.model';
export { ComplementoVO } from './models/complementovo.model';
export { VinculoFamiliaDTO } from './models/vinculofamiliadto.model';
export { MarcaHora } from './models/marcahora.model';
export { Vinculado } from './models/vinculado.model';
export { ProdutoEstabelecimentoDTO } from './models/produtoestabelecimentodto.model';
export { ProdutoVinculoVO } from './models/produtovinculovo.model';
export { ProdutosPorBandeiraRequest } from './models/produtosporbandeirarequest.model';
export { ProdutoEstabelecimentosDTO } from './models/produtoestabelecimentosdto.model';
export { ProdutosPorZonaRequest } from './models/produtosporzonarequest.model';
export { ProfissaoEntity } from './models/profissaoentity.model';
export { QuestionarioEntity } from './models/questionarioentity.model';
export { ListarDadosColetadosRequest } from './models/listardadoscoletadosrequest.model';
export { GenericCollectionResponseRoteiroDadosColetadosBase } from './models/genericcollectionresponseroteirodadoscoletadosbase.model';
export { RoteiroDadosColetadosBase } from './models/roteirodadoscoletadosbase.model';
export { GenericCollectionResponseRedeEstabelecimentoEntity } from './models/genericcollectionresponseredeestabelecimentoentity.model';
export { RegistroDePontoEntity } from './models/registrodepontoentity.model';
export { TipoMarcacaoEnum } from './models/tipomarcacaoenum.model';
export { RegistroDePontoValidatorEntity } from './models/registrodepontovalidatorentity.model';
export { ListarRegistroDePontoRequest } from './models/listarregistrodepontorequest.model';
export { GenericCollectionResponseRegistroDePontoAgrupadoDTO } from './models/genericcollectionresponseregistrodepontoagrupadodto.model';
export { RegistroDePontoAgrupadoDTO } from './models/registrodepontoagrupadodto.model';
export { GenericCollectionResponseRegistroDePontoSinteticoDTO } from './models/genericcollectionresponseregistrodepontosinteticodto.model';
export { RegistroDePontoSinteticoDTO } from './models/registrodepontosinteticodto.model';
export { AbonarDescontarRequest } from './models/abonardescontarrequest.model';
export { CancelarAbonoRequest } from './models/cancelarabonorequest.model';
export { AbonarPeriodoRequest } from './models/abonarperiodorequest.model';
export { RegistroPontoUsuarioEntity } from './models/registropontousuarioentity.model';
export { ListarRegistrosPontoRequest } from './models/listarregistrospontorequest.model';
export { GenericCollectionResponseRegistroPontoUsuarioEntity } from './models/genericcollectionresponseregistropontousuarioentity.model';
export { ListarRelatorioGeralRequest } from './models/listarrelatoriogeralrequest.model';
export { GenericCollectionResponseResumoAvaliacaoFotosDTO } from './models/genericcollectionresponseresumoavaliacaofotosdto.model';
export { ResumoAvaliacaoFotosDTO } from './models/resumoavaliacaofotosdto.model';
export { GenericCollectionResponseResumoAvaliacaoFotosGeralDTO } from './models/genericcollectionresponseresumoavaliacaofotosgeraldto.model';
export { ResumoAvaliacaoFotosGeralDTO } from './models/resumoavaliacaofotosgeraldto.model';
export { ExportarMatrizRequest } from './models/exportarmatrizrequest.model';
export { ResumoMatrizDTO } from './models/resumomatrizdto.model';
export { DiasSemana } from './models/diassemana.model';
export { GenericCollectionResponseDescontoDTO } from './models/genericcollectionresponsedescontodto.model';
export { DescontoDTO } from './models/descontodto.model';
export { GenericCollectionResponseAtendimentoEstabelecimentoDTO } from './models/genericcollectionresponseatendimentoestabelecimentodto.model';
export { AtendimentoEstabelecimentoDTO } from './models/atendimentoestabelecimentodto.model';
export { GenericCollectionResponseTempoExecucaoMarcaDTO } from './models/genericcollectionresponsetempoexecucaomarcadto.model';
export { TempoExecucaoMarcaDTO } from './models/tempoexecucaomarcadto.model';
export { RelatorioTarefasPlanejadasRequest } from './models/relatoriotarefasplanejadasrequest.model';
export { GenericCollectionResponseRelatorioPlanejadosDTO } from './models/genericcollectionresponserelatorioplanejadosdto.model';
export { RelatorioPlanejadosDTO } from './models/relatorioplanejadosdto.model';
export { GenericCollectionResponseRelatorioAnaliseExecucaoDTO } from './models/genericcollectionresponserelatorioanaliseexecucaodto.model';
export { RelatorioAnaliseExecucaoDTO } from './models/relatorioanaliseexecucaodto.model';
export { StatusRoteiroExecutadoEnum } from './models/statusroteiroexecutadoenum.model';
export { GenericCollectionResponseRelatorioAbastecimentoMarcasDTO } from './models/genericcollectionresponserelatorioabastecimentomarcasdto.model';
export { RelatorioAbastecimentoMarcasDTO } from './models/relatorioabastecimentomarcasdto.model';
export { RelatorioFotoRegistroPontoAtestadoDTO } from './models/relatoriofotoregistropontoatestadodto.model';
export { GenericCollectionResponseHorasExtrasDTO } from './models/genericcollectionresponsehorasextrasdto.model';
export { HorasExtrasDTO } from './models/horasextrasdto.model';
export { ResumoTempoTrabalhadoDashDTO } from './models/resumotempotrabalhadodashdto.model';
export { PromotoresOnlineDTO } from './models/promotoresonlinedto.model';
export { StatusPromotoresDashDTO } from './models/statuspromotoresdashdto.model';
export { RelatorioAbastecimentoMarcasDashDTO } from './models/relatorioabastecimentomarcasdashdto.model';
export { RelatorioAnaliseExecucaoDashDTO } from './models/relatorioanaliseexecucaodashdto.model';
export { ListarTempoDeslocamentoRequest } from './models/listartempodeslocamentorequest.model';
export { GenericCollectionResponseRelatorioTempoDeslocamentoDTO } from './models/genericcollectionresponserelatoriotempodeslocamentodto.model';
export { RelatorioTempoDeslocamentoDTO } from './models/relatoriotempodeslocamentodto.model';
export { ResumoDashStatusRoteiroDTO } from './models/resumodashstatusroteirodto.model';
export { GenericCollectionResponseResumoListarVersaoAtualDTO } from './models/genericcollectionresponseresumolistarversaoatualdto.model';
export { ResumoListarVersaoAtualDTO } from './models/resumolistarversaoatualdto.model';
export { GenericCollectionResponseGenericAnaliseMarcasResponseDTO } from './models/genericcollectionresponsegenericanalisemarcasresponsedto.model';
export { GenericAnaliseMarcasResponseDTO } from './models/genericanalisemarcasresponsedto.model';
export { GenericAnaliseMarcasRequest } from './models/genericanalisemarcasrequest.model';
export { GenericCollectionResponseResumoRelatorioGeralDTO } from './models/genericcollectionresponseresumorelatoriogeraldto.model';
export { ResumoRelatorioGeralDTO } from './models/resumorelatoriogeraldto.model';
export { ListarRelatorioMarcasAtendidasRequest } from './models/listarrelatoriomarcasatendidasrequest.model';
export { GenericCollectionResponseResumoRelatorioMarcasAtendidasDTO } from './models/genericcollectionresponseresumorelatoriomarcasatendidasdto.model';
export { ResumoRelatorioMarcasAtendidasDTO } from './models/resumorelatoriomarcasatendidasdto.model';
export { GenericSemRoteiroRequest } from './models/genericsemroteirorequest.model';
export { EnumSemRoteiroRequest } from './models/enumsemroteirorequest.model';
export { GenericCollectionResponseGenericSemRoteiroResponse } from './models/genericcollectionresponsegenericsemroteiroresponse.model';
export { GenericSemRoteiroResponse } from './models/genericsemroteiroresponse.model';
export { GenericCollectionResponseResumoPerformanceMarcaDTO } from './models/genericcollectionresponseresumoperformancemarcadto.model';
export { ResumoPerformanceMarcaDTO } from './models/resumoperformancemarcadto.model';
export { ListarAtrasadosRequest } from './models/listaratrasadosrequest.model';
export { GenericCollectionResponseResumoAtrasadoDTO } from './models/genericcollectionresponseresumoatrasadodto.model';
export { ResumoAtrasadoDTO } from './models/resumoatrasadodto.model';
export { ListarAtrasadosPeriodoRequest } from './models/listaratrasadosperiodorequest.model';
export { RoteiroInteligenteEstabelecimentoRequest } from './models/roteirointeligenteestabelecimentorequest.model';
export { PontoPartidaEnum } from './models/pontopartidaenum.model';
export { GenericCollectionResponseOrcamentoRoteiroAutomaticoEntity } from './models/genericcollectionresponseorcamentoroteiroautomaticoentity.model';
export { OrcamentoRoteiroAutomaticoEntity } from './models/orcamentoroteiroautomaticoentity.model';
export { VinculoLojaMarca } from './models/vinculolojamarca.model';
export { ObterRoteirosAlterarEmMassaRequest } from './models/obterroteirosalteraremmassarequest.model';
export { AlterarRoteiroEmMassaRequest } from './models/alterarroteiroemmassarequest.model';
export { ImportarRoteiroRequest } from './models/importarroteirorequest.model';
export { ListarCalendarioRoteiroPlanejadoRequest } from './models/listarcalendarioroteiroplanejadorequest.model';
export { CalendarioRoteiroPlanejadoDTO } from './models/calendarioroteiroplanejadodto.model';
export { RoteiroPlanejadoEntity } from './models/roteiroplanejadoentity.model';
export { RoteiroTarefaPlanejadaVO } from './models/roteirotarefaplanejadavo.model';
export { RoteiroAgendamentoBase } from './models/roteiroagendamentobase.model';
export { RoteiroDadosComplementaresBase } from './models/roteirodadoscomplementaresbase.model';
export { TipoRoteiroAgendamentoEnum } from './models/tiporoteiroagendamentoenum.model';
export { RoteiroInteligenteRequest } from './models/roteirointeligenterequest.model';
export { LatLgtEstabelecimentosDTO } from './models/latlgtestabelecimentosdto.model';
export { IncluirBulkTarefasRequest } from './models/incluirbulktarefasrequest.model';
export { RoteiroDadosComplementaresGaleriaFotosVO } from './models/roteirodadoscomplementaresgaleriafotosvo.model';
export { RoteiroDadosComplementaresPesquisaDinamicaIdFormularioVO } from './models/roteirodadoscomplementarespesquisadinamicaidformulariovo.model';
export { RoteiroDadosComplementaresPesquisaPrecoVO } from './models/roteirodadoscomplementarespesquisaprecovo.model';
export { RoteiroDadosComplementaresPontoPromocionalVO } from './models/roteirodadoscomplementarespontopromocionalvo.model';
export { RoteiroDadosComplementaresPesquisaConcorrenteVO } from './models/roteirodadoscomplementarespesquisaconcorrentevo.model';
export { RoteiroDadosComplementaresPontoExtraVO } from './models/roteirodadoscomplementarespontoextravo.model';
export { RoteiroDadosComplementaresMPDVVO } from './models/roteirodadoscomplementaresmpdvvo.model';
export { RoteiroDadosComplementaresPesquisaFormsVO } from './models/roteirodadoscomplementarespesquisaformsvo.model';
export { RoteiroDadosComplementaresShareGondolaVO } from './models/roteirodadoscomplementaressharegondolavo.model';
export { TipoFotoGaleriaVO } from './models/tipofotogaleriavo.model';
export { PesquisaFormsDadoComplementarVO } from './models/pesquisaformsdadocomplementarvo.model';
export { TipoCategoriaVO } from './models/tipocategoriavo.model';
export { ProdutoConcorrente } from './models/produtoconcorrente.model';
export { ProdutoObrigatorioVO } from './models/produtoobrigatoriovo.model';
export { EnumTipoPesquisaFrente } from './models/enumtipopesquisafrente.model';
export { RoteiroExecutadoEntity } from './models/roteiroexecutadoentity.model';
export { RoteiroTarefaExecutadaVO } from './models/roteirotarefaexecutadavo.model';
export { RoteiroExecutadoDadosVisitaVO } from './models/roteiroexecutadodadosvisitavo.model';
export { TempoExecucaoMarcaVO } from './models/tempoexecucaomarcavo.model';
export { StatusTarefaRoteiroExecutadoEnum } from './models/statustarefaroteiroexecutadoenum.model';
export { RegistroNaoVistaVO } from './models/registronaovistavo.model';
export { OcorrenciaAtendimentoMarcaEnum } from './models/ocorrenciaatendimentomarcaenum.model';
export { IFormFile } from './models/iformfile.model';
export { GenericCollectionResponseRelatorioRupEProdDisponivelDTO } from './models/genericcollectionresponserelatoriorupeproddisponiveldto.model';
export { RelatorioRupEProdDisponivelDTO } from './models/relatoriorupeproddisponiveldto.model';
export { FinalizarRoteiroRequest } from './models/finalizarroteirorequest.model';
export { NullableTipoTarefaRoteiroEnum } from './models/nullabletipotarefaroteiroenum.model';
export { GenericCollectionResponseFinalizarRoteirosResponse } from './models/genericcollectionresponsefinalizarroteirosresponse.model';
export { FinalizarRoteirosResponse } from './models/finalizarroteirosresponse.model';
export { TransferenciaRoteiroRequest } from './models/transferenciaroteirorequest.model';
export { MudancaUsuarioRequest } from './models/mudancausuariorequest.model';
export { RelatorioEpiRequest } from './models/relatorioepirequest.model';
export { EnumTipoEPI } from './models/enumtipoepi.model';
export { GenericCollectionResponseRelatorioEPIDTO } from './models/genericcollectionresponserelatorioepidto.model';
export { RelatorioEPIDTO } from './models/relatorioepidto.model';
export { RoteiroPlanejadoAutomaticoEntity } from './models/roteiroplanejadoautomaticoentity.model';
export { RoteirosAutomaticos } from './models/roteirosautomaticos.model';
export { FuturoPlanejadoVO } from './models/futuroplanejadovo.model';
export { OrcamentoRAutomaticoRequest } from './models/orcamentorautomaticorequest.model';
export { LatLngRoteiroAutomaticoDTO } from './models/latlngroteiroautomaticodto.model';
export { GenericCollectionResponseSegmentoProdutoEntity } from './models/genericcollectionresponsesegmentoprodutoentity.model';
export { SemVendasPlanejadoEntity } from './models/semvendasplanejadoentity.model';
export { SvLojaPlanejada } from './models/svlojaplanejada.model';
export { SvRede } from './models/svrede.model';
export { SvFamiliaPlanejada } from './models/svfamiliaplanejada.model';
export { SvProdutoPlanejado } from './models/svprodutoplanejado.model';
export { SemVendasExecutadoEntity } from './models/semvendasexecutadoentity.model';
export { SvLojaExecutada } from './models/svlojaexecutada.model';
export { SvFamiliaExecutada } from './models/svfamiliaexecutada.model';
export { SvProdutoExecutado } from './models/svprodutoexecutado.model';
export { TipoUnidadeContagemProdutoEnum } from './models/tipounidadecontagemprodutoenum.model';
export { GenericCollectionResponseSemVendasListarDTO } from './models/genericcollectionresponsesemvendaslistardto.model';
export { SemVendasListarDTO } from './models/semvendaslistardto.model';
export { SubCanalEntity } from './models/subcanalentity.model';
export { GenericCollectionResponseSubCanalEntity } from './models/genericcollectionresponsesubcanalentity.model';
export { SubCategoriaProdutoEntity } from './models/subcategoriaprodutoentity.model';
export { GenericCollectionResponseSubCategoriaProdutoEntity } from './models/genericcollectionresponsesubcategoriaprodutoentity.model';
export { SubCategoriaProdutoDTO } from './models/subcategoriaprodutodto.model';
export { SubirFotoRequest } from './models/subirfotorequest.model';
export { TimelineDTO } from './models/timelinedto.model';
export { ITimelineDetalhes } from './models/itimelinedetalhes.model';
export { TimeLineRequest } from './models/timelinerequest.model';
export { TimeLineBateriaDTO } from './models/timelinebateriadto.model';
export { TimeLineRoteiroDTO } from './models/timelineroteirodto.model';
export { TimeLineLocalizacaoDTO } from './models/timelinelocalizacaodto.model';
export { TimeLineDadosDTO } from './models/timelinedadosdto.model';
export { TimeLineListarDTO } from './models/timelinelistardto.model';
export { UnidadeNegocioProprietarioEntity } from './models/unidadenegocioproprietarioentity.model';
export { GenericCollectionResponseUnidadeNegocioProprietarioEntity } from './models/genericcollectionresponseunidadenegocioproprietarioentity.model';
export { UsuarioCadastroEntity } from './models/usuariocadastroentity.model';
export { FrequenciaTrabalho } from './models/frequenciatrabalho.model';
export { TipoLocomocao } from './models/tipolocomocao.model';
export { GenericCollectionResponseUsuarioCadastroEntity } from './models/genericcollectionresponseusuariocadastroentity.model';
export { UsuarioCadastroEnumsSync } from './models/usuariocadastroenumssync.model';
export { UsuarioCadastroEnumVO } from './models/usuariocadastroenumvo.model';
export { GenericCollectionResponseUsuarioSistemaEntity } from './models/genericcollectionresponseusuariosistemaentity.model';
export { AlterarSenhaRequest } from './models/alterarsenharequest.model';
export { RegistrarSessaoRequest } from './models/registrarsessaorequest.model';
export { IncluirFichaCadastralRequest } from './models/incluirfichacadastralrequest.model';
export { BaixarHoleriteRequest } from './models/baixarholeriterequest.model';
export { UploadHoleriteRequest } from './models/uploadholeriterequest.model';
export { BaixarInformeRequest } from './models/baixarinformerequest.model';
export { TransferirSubordinadoRequest } from './models/transferirsubordinadorequest.model';
export { LoginRequest } from './models/loginrequest.model';
export { Token } from './models/token.model';
export { UsuarioEstabelecimentoRequest } from './models/usuarioestabelecimentorequest.model';
export { GenericCollectionResponseUsuarioEstabelecimentoDTO } from './models/genericcollectionresponseusuarioestabelecimentodto.model';
export { UsuarioEstabelecimentoDTO } from './models/usuarioestabelecimentodto.model';
export { TimelineUsuariosRequest } from './models/timelineusuariosrequest.model';
export { UsuarioTimelineDTO } from './models/usuariotimelinedto.model';
export { VendedorDTO } from './models/vendedordto.model';
export { VendedorEstabelecimentoRequest } from './models/vendedorestabelecimentorequest.model';
export { GenericCollectionResponseVendedorEstabelecimentoDTO } from './models/genericcollectionresponsevendedorestabelecimentodto.model';
export { VendedorEstabelecimentoDTO } from './models/vendedorestabelecimentodto.model';
export { GenericCollectionResponseUsuarioAtivoDTO } from './models/genericcollectionresponseusuarioativodto.model';
export { UsuarioAtivoDTO } from './models/usuarioativodto.model';
export { ValorMarcaEntity } from './models/valormarcaentity.model';
export { TipoValorMarcaEnum } from './models/tipovalormarcaenum.model';
export { GenericCollectionResponseValorMarcaDTO } from './models/genericcollectionresponsevalormarcadto.model';
export { ValorMarcaDTO } from './models/valormarcadto.model';
export { GenericCollectionResponseDemonstrativoExpressDTO } from './models/genericcollectionresponsedemonstrativoexpressdto.model';
export { DemonstrativoExpressDTO } from './models/demonstrativoexpressdto.model';
export { RoteiroAgendamentoUnicoVO } from './models/roteiroagendamentounicovo.model';
export { RoteiroAgendamentoQuinzenalVO } from './models/roteiroagendamentoquinzenalvo.model';
export { RoteiroAgendamentoDiarioVO } from './models/roteiroagendamentodiariovo.model';
export { RoteiroAgendamentoSemanalVO } from './models/roteiroagendamentosemanalvo.model';
export { RoteiroAgendamentoMensalVO } from './models/roteiroagendamentomensalvo.model';
export { TipoFrequenciaMensalEnum } from './models/tipofrequenciamensalenum.model';
export { NullableDayOfWeek } from './models/nullabledayofweek.model';
export { RoteiroDadosRupturaEntity } from './models/roteirodadosrupturaentity.model';
export { TipoRupturaProdutoEnum } from './models/tiporupturaprodutoenum.model';
export { RoteiroDadosReabastecimentoEntity } from './models/roteirodadosreabastecimentoentity.model';
export { EanAbastecidoVo } from './models/eanabastecidovo.model';
export { RoteiroDadosCMEntity } from './models/roteirodadoscmentity.model';
export { RoteiroDadosPontoExtraEntity } from './models/roteirodadospontoextraentity.model';
export { TipoNegociacaoPontoExtraEnum } from './models/tiponegociacaopontoextraenum.model';
export { TipoPontoExtraEnum } from './models/tipopontoextraenum.model';
export { RoteiroDadosMPDVEntity } from './models/roteirodadosmpdventity.model';
export { DadosMPDVVO } from './models/dadosmpdvvo.model';
export { TipoMPDVEnum } from './models/tipompdvenum.model';
export { RoteiroDadosPesquisaValidadeEntity } from './models/roteirodadospesquisavalidadeentity.model';
export { RoteiroDadosCaixasAbertasEntity } from './models/roteirodadoscaixasabertasentity.model';
export { RoteiroDadosPesquisaEstoqueEntity } from './models/roteirodadospesquisaestoqueentity.model';
export { RoteiroDadosPesquisaPrecoEntity } from './models/roteirodadospesquisaprecoentity.model';
export { PrecoConcorrenteVO } from './models/precoconcorrentevo.model';
export { RoteiroDadosGaleriaFotosEntity } from './models/roteirodadosgaleriafotosentity.model';
export { FotoGaleriaVO } from './models/fotogaleriavo.model';
export { RoteiroDadosSugestaoPedidoEntity } from './models/roteirodadossugestaopedidoentity.model';
export { RoteiroDadosCamaraFriaEntity } from './models/roteirodadoscamarafriaentity.model';
export { RoteiroDadosRegistroPresencaEntiy } from './models/roteirodadosregistropresencaentiy.model';
export { RoteiroDadosShareGondolaEntity } from './models/roteirodadossharegondolaentity.model';
export { RoteiroDadosPontoPromocionalEntity } from './models/roteirodadospontopromocionalentity.model';
export { SituacaoInstalacaoPontoPromocional } from './models/situacaoinstalacaopontopromocional.model';
export { RoteiroDadosFormularioDinamicoEntity } from './models/roteirodadosformulariodinamicoentity.model';
export { FormularioDinamicoRespotaVO } from './models/formulariodinamicorespotavo.model';
export { RoteiroDadosRegistroFotosEntity } from './models/roteirodadosregistrofotosentity.model';
export { RoteiroDadosInformarCNPJEntity } from './models/roteirodadosinformarcnpjentity.model';
export { RoteiroDadosPesquisaPrecoProdutoEntity } from './models/roteirodadospesquisaprecoprodutoentity.model';
export { EnumTipoComercializacao } from './models/enumtipocomercializacao.model';
export { EnumTipoPromocao } from './models/enumtipopromocao.model';
export { RoteiroDadosProdutoLojaEntity } from './models/roteirodadosprodutolojaentity.model';
export { RoteiroDadosCheckListEPIEntity } from './models/roteirodadoschecklistepientity.model';
export { RoteiroDadosPesquisaDinamicaEntity } from './models/roteirodadospesquisadinamicaentity.model';
export { RoteiroDadosCamaraFriaGeralEntity } from './models/roteirodadoscamarafriageralentity.model';
export { EnumTipoCamaraFria } from './models/enumtipocamarafria.model';
export { RoteiroDadosProspeccaoEntity } from './models/roteirodadosprospeccaoentity.model';
export { RoteiroDadosDescarteEntity } from './models/roteirodadosdescarteentity.model';
export { RoteiroDadosPesquisaConcorrenteEntity } from './models/roteirodadospesquisaconcorrenteentity.model';
export { ArquivoExpressEntity } from './models/arquivoexpressentity.model';
export { RoteiroDadosPesquisaPrecoDTO } from './models/roteirodadospesquisaprecodto.model';
export { RoteiroDadosFormularioDinamicoDTO } from './models/roteirodadosformulariodinamicodto.model';
export { RoteiroDadosPesquisaConcorrenteDTO } from './models/roteirodadospesquisaconcorrentedto.model';
export { RoteiroDadosMPDVDTO } from './models/roteirodadosmpdvdto.model';
