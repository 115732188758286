
export enum StatusRoteiroExecutadoEnum {
  NaoIniciado = 'NaoIniciado',
  Iniciado = 'Iniciado',
  FinalizadoPorCheckout = 'FinalizadoPorCheckout',
  FinalizadoPorNaoVisita = 'FinalizadoPorNaoVisita',
  FinalizadoPeloSupervisor = 'FinalizadoPeloSupervisor',
  FinalizadoPeloSistema = 'FinalizadoPeloSistema',
  Pausado = 'Pausado',
}
