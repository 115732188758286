
export enum TipoUnidadeContagemProdutoEnum {
  Unidade = 'Unidade',
  Caixa = 'Caixa',
  Quilo = 'Quilo',
  Litro = 'Litro',
  Fardo = 'Fardo',
  Engradado = 'Engradado',
  PackUnificado = 'PackUnificado',
  Palete = 'Palete',
  Cesto = 'Cesto',
}
