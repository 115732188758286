import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dsAtivoInativo'
})
export class DsAtivoInativoPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value ? "Inativo" : "Ativo";
  }
}
