import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-unauthorized',
    templateUrl: 'unauthorized.component.html'
})

export class UnauthorizedComponent implements OnInit {

    public message: string;
    public values: any[] = [];

    constructor() {
        this.message = 'UnauthorizedComponent constructor';
    }

    public ngOnInit(): void {
        console.log('start login');

        // this.oidcSecurityService.setCustomRequestParameters({ 'ui_locales': 'pt-BR' });

        // this.oidcSecurityService.authorize();
    }
}
