import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'engSimNao'
})
export class EngSimNaoPipe implements PipeTransform {

    constructor() {

    }

    transform(phrase: any): any {
        return phrase == true ? "Sim" : phrase == false ? "Não" : "-";
    }
}
