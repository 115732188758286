
export enum TipoMPDVEnum {
  Wobbler = 'Wobbler',
  Banner = 'Banner',
  FaixaGondola = 'FaixaGondola',
  ClipStrip = 'ClipStrip',
  PapelForracao = 'PapelForracao',
  Mobly = 'Mobly',
  Cubo = 'Cubo',
  Splash = 'Splash',
  Cartaz = 'Cartaz',
  AdesivoChao = 'AdesivoChao',
  NA = 'NA',
}
