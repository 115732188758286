import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UsuarioSistemaBase, TelasEnum, ConfiguracaoWebDTO } from '../services-generated/models';
import { Token } from "../services-generated/models/token.model";
import { Telas } from '../shared/listas/telas';
import { Claims } from './models/claims.model';
import { TelasEnumInt } from '../shared/telas.enum';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  paginasLiberadas = [
    "/home",
    "/usuario-sistema/alterar-senha"
  ];

  constructor(public router: Router,
    private jwtHelper: JwtHelperService) { }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('userToken');
    if (token !== null && token !== 'null' && token !== undefined && token !== 'undefined') {
      var validade = this.jwtHelper.getTokenExpirationDate(token);
      return validade > new Date();
    }
    return false;
  }

  public obterClaims(): Claims {
    let dados = localStorage.getItem("userToken");
    let claims: Claims;
    try {
      claims = this.jwtHelper.decodeToken(dados) as Claims;
      claims.config_site = JSON.parse(''+claims.config_site);
      claims.marcas = JSON.parse(''+claims.marcas);
      if (typeof(claims.marcas) !== typeof([''])) claims.marcas = [];
    }
    catch {
      localStorage.removeItem("userData");
      localStorage.removeItem("userToken");
      this.router.navigate(['/entrar']);
    }
    return claims;
  }

  public obterUsuarioLogado(): UsuarioSistemaBase {
    let claims = this.obterClaims();
    return {
      _id: claims.id,
      Nome: claims.nome,
      Login: claims.login,
      DataPersistenciaRegistro: new Date()
    } as UsuarioSistemaBase;
  }

  public adminDoSistema(): boolean {
    var dados = this.obterClaims();
    return dados.perfil === 'adminsistema';
  }

  public admin(): boolean {
    var dados = this.obterClaims();
    return dados.perfil === 'admin' || dados.perfil === 'adminsistema';
  }

  public podeAcessar(caminho: string): boolean {
    if (this.paginasLiberadas.indexOf(caminho) !== -1) return true;

    let token = localStorage.getItem("userToken");
    //let grupoTela = Telas.find(_ => _.itens.find(item => this.caminhoRegex(item.caminho).test(caminho)) !== undefined);
    let grupoTela = Telas.find(_ => _.itens.find(item => item.caminho == caminho) !== undefined);

    if (grupoTela === undefined) {
      return this.adminDoSistema();
    }

    //let tela = grupoTela.itens.find(_ => this.caminhoRegex(_.caminho).test(caminho));
    let tela = grupoTela.itens.find(_ => _.caminho == caminho);

    let config = JSON.parse(this.jwtHelper.decodeToken(token).config_site) as ConfiguracaoWebDTO;

    if (tela === undefined || config == undefined) return false;
    let podeAcessar = config.TelasPermitidas.find(_ => tela.valor == _) !== undefined;

    return podeAcessar;
  }

  private caminhoRegex(stringCaminho): RegExp {
    let regex = stringCaminho.replace(/\//g, '\\\/');
    return new RegExp(regex);
  }

  verificarAcessoPagina(tela: TelasEnum): boolean {
    try {
      var dados = localStorage.getItem("userToken");
      var token = this.jwtHelper.decodeToken(dados);
      let config = JSON.parse(token.config_site) as ConfiguracaoWebDTO;
      if (config.TelasPermitidas.some(_ => _ == tela)) {
        return true;
      } else {
        return false;
      }
    }
    catch {
      return false;
    }
  }

  public verificarAcessoPaginaLista(telas: TelasEnum[]): boolean {
    try {
      var dados = localStorage.getItem("userToken");
      var token = this.jwtHelper.decodeToken(dados);
      let config = JSON.parse(token.config_site) as ConfiguracaoWebDTO;
      let podeVer = false;
      for (let tela of telas) {
        if (config.TelasPermitidas.some(_ => _ == tela)) {
          podeVer = true;
          break;
        }
      }
      return podeVer;
    }
    catch {
      return false;
    }
  }

  public perfilPromotor(): boolean {
    let perfis = [
      "promotor",
      "promotorsemgps",
      "promotor6",
      "promotor6semgps",
      "parttime4",
      "parttime4semgps",
      "parttime5",
      "parttime5semgps",
      "parttime6",
      "parttime6semgps",
      "promotorlivre",
      "promotorlivresemgps",
      "promotor8sabado",
      "promotor8sabadosemgps",
      "promotorsemvendas",
      "promotorsemvendassemgps",
      "aguia",
      "aguiasemgps",
      "aguiasemalmoco",
      "aguiasemalmocogps",
      "promotor548",
      "promotor5485h",
      "promotorexpress",
      "auxiliarlimpeza"
    ];
    if (perfis.indexOf(this.obterClaims().perfil) != -1) {
      return true;
    }
    return false;
  }

  public logout() {
    localStorage.removeItem('userToken');
    this.router.navigate(['/entrar']);
  }

  public obterGrupoTelas(tela: TelasEnum) {
    console.log(tela);
    let telaEnum = TelasEnumInt[tela];
    console.log(telaEnum);
  }
}
