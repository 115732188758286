import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {
  isAuthorizedSubscription: Subscription | undefined;
  isAuthorized = false;

  onChecksessionChanged: Subscription | undefined;

  checksession = false;
  hash = '';

  constructor(private router: Router,
    private authService: AuthService) {

      console.log('AppComponent STARTING');
      this.isAuthorized = this.authService.isAuthenticated();
  }

  ngOnInit() {

  }

  ngOnDestroy(): void {
    if (this.isAuthorizedSubscription) {
      this.isAuthorizedSubscription.unsubscribe();
    }
  }

  login() {
    console.log('start login');
  }

  refreshSession() {

  }

  logout() {

  }
}
