import { Pipe, PipeTransform } from '@angular/core';
import { EnumTipoItemPesquisaForms } from '../../services-generated/models';

@Pipe({
    name: 'pesquisaForms',
    pure: true
})
export class PesquisaFormsPipe implements PipeTransform {

    transform(value: EnumTipoItemPesquisaForms, args?: any): string {
        switch (value) {
            case EnumTipoItemPesquisaForms.Switch:
                return "Sim/Não";
            case EnumTipoItemPesquisaForms.Entry:
                return "Pergunta e resposta";
            case EnumTipoItemPesquisaForms.DropDown:
                return "opções";
            case EnumTipoItemPesquisaForms.Label:
                return "Texto";
            case EnumTipoItemPesquisaForms.DropDownMult:
                return "Múltipla escolha";
            case EnumTipoItemPesquisaForms.EAN:
                return "Informar EAN";
            case EnumTipoItemPesquisaForms.BarCode:
                return "Código de barras";
            case EnumTipoItemPesquisaForms.Pesquisa:
                return "Pesquisa";
            case EnumTipoItemPesquisaForms.PriceEntry:
                return "Coleta de preço";
            case EnumTipoItemPesquisaForms.EsteiraProduto:
                return "Esteira produto";
            case EnumTipoItemPesquisaForms.DropDownPesquisa:
                return "Opções com pesquisa";
            case EnumTipoItemPesquisaForms.DropDownMultPesquisa:
                return "Múltiplas opções com pesquisa";
            case EnumTipoItemPesquisaForms.DropDownFoto:
                return "Dropdown com foto";
            case EnumTipoItemPesquisaForms.DropDownMultFoto:
                return "Múltipla escolha com foto";
            default:
                break;
        }
    }

}
