
export enum TipoTarefaRoteiroEnum {
  Reabastecimento = 'Reabastecimento',
  Ruptura = 'Ruptura',
  Estoque = 'Estoque',
  Validade = 'Validade',
  CaixasAbertas = 'CaixasAbertas',
  PesquisaPreco = 'PesquisaPreco',
  GaleriaFotos = 'GaleriaFotos',
  SugestaoPedido = 'SugestaoPedido',
  CamaraFria = 'CamaraFria',
  RegistroPresenca = 'RegistroPresenca',
  ShareGondola = 'ShareGondola',
  PontoPromocional = 'PontoPromocional',
  FormularioDinamico = 'FormularioDinamico',
  RegistroFotos = 'RegistroFotos',
  InformarCNPJ = 'InformarCNPJ',
  PesquisaProduto = 'PesquisaProduto',
  ProdutoLoja = 'ProdutoLoja',
  CheckListEPI = 'CheckListEPI',
  PesquisaDinamica = 'PesquisaDinamica',
  CamaraFriaGeral = 'CamaraFriaGeral',
  Prospeccao = 'Prospeccao',
  Descarte = 'Descarte',
  CM = 'CM',
  MPDV = 'MPDV',
  PontoExtra = 'PontoExtra',
  PesquisaConcorrente = 'PesquisaConcorrente',
  PesquisaForms = 'PesquisaForms',
  ArquivoExpress = 'ArquivoExpress',
}
