
export enum TipoPontoExtraEnum {
  Ilha = 'Ilha',
  Pilha = 'Pilha',
  PGondola = 'PGondola',
  CheckStand = 'CheckStand',
  BackLight = 'BackLight',
  ClipStrip = 'ClipStrip',
  CrossMerchandising = 'CrossMerchandising',
  Display = 'Display',
}
