
export enum NullableStatusUsuarioEnum {
  Ativo = 'Ativo',
  Inativo = 'Inativo',
  Ferias = 'Ferias',
  Suspenso = 'Suspenso',
  Licenca = 'Licenca',
  AvisoPrevio = 'AvisoPrevio',
  Desligado = 'Desligado',
  CadastroCancelado = 'CadastroCancelado',
}
