import { Route } from '@angular/compiler/src/core';
import { Injectable } from '@angular/core';
import { CanLoad, Router, UrlSegment } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthorizationCanGuard implements CanLoad {

    constructor(private router: Router,
                private authService: AuthService) {}

    // TODO: implementar canLoad corretamente
    canLoad(route: Route): boolean {
        return true;
        //return this.authService.podeAcessar((route as any).path);
    }
}
