import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from './auth.service';

@Injectable()
export class AuthorizationGuard implements CanActivate {

    constructor(
        private router: Router,
        private authService: AuthService) { }

    canActivate(next: ActivatedRouteSnapshot,state: RouterStateSnapshot): boolean {
        if (localStorage.getItem('userToken') !== null &&
            localStorage.getItem('userToken') !== '' &&
            localStorage.getItem('userToken') !== 'null' &&
            localStorage.getItem('userToken') !== undefined) {
                let podeAcessarUrl = this.authService.podeAcessar(state.url);
                if (podeAcessarUrl) {
                    return true;
                }
                else {
                    console.error("Seu usuário não tem permissão para acessar a página " + state.url, "Acesso negado");
                    this.router.navigate(['/home']);
                    return false;
                }
        }
        else {
            console.error("É necessário entrar novamente", "Sessão expirada");
            this.router.navigate(['/entrar']);
            return false;
        }
    }
}
