
export enum OcorrenciaAtendimentoMarcaEnum {
  nenhum = 'nenhum',
  empilhadeira = 'empilhadeira',
  balanco = 'balanco',
  estoque = 'estoque',
  naoautorizado = 'naoautorizado',
  outros = 'outros',
  impressoraQuebrada = 'impressoraQuebrada',
}
