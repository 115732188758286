
export enum TelasEnum {
  PaginaInicial = 'PaginaInicial',
  PaginaInicialBi = 'PaginaInicialBi',
  Usuarios = 'Usuarios',
  UsuariosGerenciar = 'UsuariosGerenciar',
  UsuariosSubirFicha = 'UsuariosSubirFicha',
  UsuariosTransferirSubordinados = 'UsuariosTransferirSubordinados',
  UsuariosEstabelecimentos = 'UsuariosEstabelecimentos',
  UsuariosCandidatos = 'UsuariosCandidatos',
  FormulariosDinamicos = 'FormulariosDinamicos',
  TarefasPersonalizadas = 'TarefasPersonalizadas',
  Roteiros = 'Roteiros',
  RoteirosConsultar = 'RoteirosConsultar',
  RoteirosIncluirSistematicaRoteiro = 'RoteirosIncluirSistematicaRoteiro',
  RoteirosIncluirSistematicaFixo = 'RoteirosIncluirSistematicaFixo',
  RoteirosFinalizarPorCliente = 'RoteirosFinalizarPorCliente',
  RoteirosFinalizarPorPromotor = 'RoteirosFinalizarPorPromotor',
  RoteirosTransferir = 'RoteirosTransferir',
  RoteirosIncluirEmMassa = 'RoteirosIncluirEmMassa',
  RoteirosAutomatico = 'RoteirosAutomatico',
  RoteirosInteligente = 'RoteirosInteligente',
  ImportarRoteiro = 'ImportarRoteiro',
  RoteirosAlterarEmMassa = 'RoteirosAlterarEmMassa',
  Timeline = 'Timeline',
  AlbunsDeFotos = 'AlbunsDeFotos',
  Cartas = 'Cartas',
  CartasCriar = 'CartasCriar',
  CartasGerenciarEmpresas = 'CartasGerenciarEmpresas',
  CartasModelos = 'CartasModelos',
  Estabelecimentos = 'Estabelecimentos',
  EstabelecimentosRedes = 'EstabelecimentosRedes',
  EstabelecimentosBandeiras = 'EstabelecimentosBandeiras',
  EstabelecimentosEstabelecimentos = 'EstabelecimentosEstabelecimentos',
  EstabelecimentosSubcanais = 'EstabelecimentosSubcanais',
  Produtos = 'Produtos',
  ProdutosCategorias = 'ProdutosCategorias',
  ProdutosSubcategorias = 'ProdutosSubcategorias',
  ProdutosSegmentos = 'ProdutosSegmentos',
  ProdutosMarcas = 'ProdutosMarcas',
  ProdutosFamilias = 'ProdutosFamilias',
  ProdutosProdutos = 'ProdutosProdutos',
  ProdutosVincular = 'ProdutosVincular',
  ProdutosVincularPorBandeira = 'ProdutosVincularPorBandeira',
  ProdutosVincularPorZona = 'ProdutosVincularPorZona',
  Relatorios = 'Relatorios',
  RelatoriosDadosColetados = 'RelatoriosDadosColetados',
  RelatoriosProdutosEmRuptura = 'RelatoriosProdutosEmRuptura',
  RelatoriosLogsDispositivos = 'RelatoriosLogsDispositivos',
  RelatoriosLogsGeolocalizacao = 'RelatoriosLogsGeolocalizacao',
  RelatoriosRegistroPontoAnalitico = 'RelatoriosRegistroPontoAnalitico',
  RelatoriosRegistroPontoSintetico = 'RelatoriosRegistroPontoSintetico',
  RelatoriosRegistroAusencia = 'RelatoriosRegistroAusencia',
  RelatoriosRegistroAusenciaExpress = 'RelatoriosRegistroAusenciaExpress',
  RelatoriosMonitoramentoOperacao = 'RelatoriosMonitoramentoOperacao',
  RelatoriosHorasPorMarca = 'RelatoriosHorasPorMarca',
  RelatoriosTempoDeslocamento = 'RelatoriosTempoDeslocamento',
  RelatoriosHorasExtras = 'RelatoriosHorasExtras',
  RelatoriosQuestionarios = 'RelatoriosQuestionarios',
  RelatoriosClientesPorEstabelecimento = 'RelatoriosClientesPorEstabelecimento',
  RelatoriosSistematicaPorCliente = 'RelatoriosSistematicaPorCliente',
  RelatoriosSemRoteiro = 'RelatoriosSemRoteiro',
  RelatoriosDescontos = 'RelatoriosDescontos',
  RelatoriosEPI = 'RelatoriosEPI',
  RelatoriosJustificativaPonto = 'RelatoriosJustificativaPonto',
  RelatoriosPlanejados = 'RelatoriosPlanejados',
  RelatoriosBalanceamento = 'RelatoriosBalanceamento',
  RelatoriosTempoExecucao = 'RelatoriosTempoExecucao',
  RelatoriosGerenciais = 'RelatoriosGerenciais',
  RelatoriosGerenciaisAnaliseExecucao = 'RelatoriosGerenciaisAnaliseExecucao',
  RelatoriosGerenciaisPerformance = 'RelatoriosGerenciaisPerformance',
  RelatoriosGerenciaisPerformanceFotos = 'RelatoriosGerenciaisPerformanceFotos',
  RelatoriosGerenciaisPerformancePorCliente = 'RelatoriosGerenciaisPerformancePorCliente',
  RelatoriosGerenciaisPerformanceFotosPorCliente = 'RelatoriosGerenciaisPerformanceFotosPorCliente',
  RelatoriosGerenciaisExportarMatriz = 'RelatoriosGerenciaisExportarMatriz',
  RelatoriosGerenciaisRelacaoProdutosLojas = 'RelatoriosGerenciaisRelacaoProdutosLojas',
  RelatoriosGerenciaisDemonstrativoExpress = 'RelatoriosGerenciaisDemonstrativoExpress',
  RelatoriosGerenciaisUsuariosAtivos = 'RelatoriosGerenciaisUsuariosAtivos',
  Documentos = 'Documentos',
  DocumentosPLR = 'DocumentosPLR',
  DocumentosBaixarHolerite = 'DocumentosBaixarHolerite',
  DocumentosUploadHolerite = 'DocumentosUploadHolerite',
  DocumentosInformeRendimentos = 'DocumentosInformeRendimentos',
  Mei = 'Mei',
  MeiIncluirPagamento = 'MeiIncluirPagamento',
  SubirFoto = 'SubirFoto',
  SubirFotoSubirFoto = 'SubirFotoSubirFoto',
  AtualizacaoCadastral = 'AtualizacaoCadastral',
  AtualizacaoCadastralCadastrar = 'AtualizacaoCadastralCadastrar',
  AtualizacaoCadastralConsultar = 'AtualizacaoCadastralConsultar',
  Orcamento = 'Orcamento',
  OrcamentoConsultar = 'OrcamentoConsultar',
  OrcamentoUpload = 'OrcamentoUpload',
  OrcamentoGerarRota = 'OrcamentoGerarRota',
  ValorMarca = 'ValorMarca',
  Feriado = 'Feriado',
  Dashboard = 'Dashboard',
  NotificacaoMobile = 'NotificacaoMobile',
  NotificacaoMobileIncluir = 'NotificacaoMobileIncluir',
}
