import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from './loader.service';
import { LoaderState } from './loader-state';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'eng-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.css']
})
export class LoaderComponent implements OnInit, OnDestroy {
  show = false;
  private subscription: Subscription;
  constructor(private loaderService: LoaderService, private changeDetector: ChangeDetectorRef) { }

  ngOnInit() {
    this.subscription = this.loaderService.loaderState
    .subscribe((state: LoaderState) => {
      // console.log('Loader component chamado');      
      this.show = state.show;
      this.changeDetector.detectChanges();
    });
  }
  
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
