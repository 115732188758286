import { Routes, RouterModule } from '@angular/router';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import {LoginComponent} from "./auth/login/login.component";

const ROUTES: Routes = [
    { path: '', loadChildren: './layout/layout.module#LayoutModule' },
    { path: 'forbidden', component: ForbiddenComponent },
    { path: 'unauthorized', component: UnauthorizedComponent },
    { path: 'entrar', component: LoginComponent }
];

export const AppRouting = RouterModule.forRoot(ROUTES);
