export enum TelasEnumInt {
  PaginaInicial = 0,
  PaginaInicialBi,

  Usuarios = 1000,
  UsuariosGerenciar,
  UsuariosSubirFicha,
  UsuariosTransferirSubordinados,
  UsuariosEstabelecimentos,

  FormulariosDinamicos = 2000,

  TarefasPersonalizadas = 3000,

  Roteiros = 4000,
  RoteirosConsultar,
  RoteirosIncluirSistematicaRoteiro,
  RoteirosIncluirSistematicaFixo,
  RoteirosFinalizarPorCliente,
  RoteirosFinalizarPorPromotor,
  RoteirosTransferir,
  RoteirosIncluirEmMassa,
  RoteirosAutomatico,
  RoteirosInteligente,
  ImportarRoteiro,

  Timeline = 5000,

  AlbunsDeFotos = 6000,

  Cartas = 7000,
  CartasCriar,
  CartasGerenciarEmpresas,
  CartasModelos,

  Estabelecimentos = 8000,
  EstabelecimentosRedes,
  EstabelecimentosBandeiras,
  EstabelecimentosEstabelecimentos,

  Produtos = 9000,
  ProdutosCategorias,
  ProdutosSubcategorias,
  ProdutosSegmentos,
  ProdutosMarcas,
  ProdutosFamilias,
  ProdutosProdutos,
  ProdutosVincular,
  ProdutosVincularPorBandeira,

  Relatorios = 10000,
  RelatoriosDadosColetados,
  RelatoriosProdutosEmRuptura,
  RelatoriosLogsDispositivos,
  RelatoriosLogsGeolocalizacao,
  RelatoriosRegistroPontoAnalitico,
  RelatoriosRegistroPontoSintetico,
  RelatoriosRegistroAusencia,
  RelatoriosRegistroAusenciaExpress,
  RelatoriosMonitoramentoOperacao,
  RelatoriosHorasPorMarca,
  RelatoriosTempoDeslocamento,
  RelatoriosHorasExtras,
  RelatoriosQuestionarios,
  RelatoriosClientesPorEstabelecimento,
  RelatoriosSistematicaPorCliente,
  RelatoriosSemRoteiro,
  RelatoriosDescontos,
  RelatoriosEPI,
  RelatoriosJustificativaPonto,
  RelatoriosPlanejados,
  RelatoriosBalanceamento,
  RelatoriosTempoExecucao,

  RelatoriosGerenciais = 11000,
  RelatoriosGerenciaisAnaliseExecucao,
  RelatoriosGerenciaisPerformance,
  RelatoriosGerenciaisPerformanceFotos,
  RelatoriosGerenciaisPerformancePorCliente,
  RelatoriosGerenciaisPerformanceFotosPorCliente,
  RelatoriosGerenciaisExportarMatriz,
  RelatoriosGerenciaisRelacaoProdutosLojas,
  RelatoriosGerenciaisDemonstrativoExpress,

  Documentos = 12000,
  DocumentosPLR,
  DocumentosBaixarHolerite,
  DocumentosUploadHolerite,
  DocumentosInformeRendimentos,

  Mei = 13000,
  MeiIncluirPagamento,

  SubirFoto = 14000,
  SubirFotoSubirFoto,

  AtualizacaoCadastral = 15000,
  AtualizacaoCadastralCadastrar,
  AtualizacaoCadastralConsultar,

  Orcamento = 16000,
  OrcamentoConsultar,
  OrcamentoUpload,
  OrcamentoGerarRota,

  ValorMarca = 17000,

  Feriado = 18000,

  Dashboard = 19000,
  
  NotificacaoMobile = 20000,
  NotificacaoMobileIncluir,
  NotificacaoMobileCancelar
}
