import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidadorDocService {

  constructor() { }

  CPF(cpf: string): boolean {
    if (cpf == null) {
      return false;
    }
    if (cpf.length != 11) {
      return false;
    }
    if ((cpf == '00000000000')
      || (cpf == '11111111111')
      || (cpf == '22222222222')
      || (cpf == '33333333333')
      || (cpf == '44444444444')
      || (cpf == '55555555555')
      || (cpf == '66666666666')
      || (cpf == '77777777777')
      || (cpf == '88888888888')
      || (cpf == '99999999999')) {
      return false;
    }
    let numero = 0;
    let caracter = '';
    let numeros = '0123456789';
    let j = 10;
    let somatorio = 0;
    let resto = 0;
    let digito1 = 0;
    let digito2 = 0;
    let cpfAux = '';
    cpfAux = cpf.substring(0, 9);
    for (let i = 0; i < 9; i++) {
      caracter = cpfAux.charAt(i);
      if (numeros.search(caracter) == -1) {
        return false;
      }
      numero = Number(caracter);
      somatorio = somatorio + (numero * j);
      j--;
    }
    resto = somatorio % 11;
    digito1 = 11 - resto;
    if (digito1 > 9) {
      digito1 = 0;
    }
    j = 11;
    somatorio = 0;
    cpfAux = cpfAux + digito1;
    for (let i = 0; i < 10; i++) {
      caracter = cpfAux.charAt(i);
      numero = Number(caracter);
      somatorio = somatorio + (numero * j);
      j--;
    }
    resto = somatorio % 11;
    digito2 = 11 - resto;
    if (digito2 > 9) {
      digito2 = 0;
    }
    cpfAux = cpfAux + digito2;
    if (cpf != cpfAux) {
      return false;
    } else {
      return true;
    }
  }

  ValidarCNPJ(cnpj: string): boolean {
    if (cnpj === '00000000000000') { return false; }
    let valida = new Array(6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2);
    let dig1 = 0;
    let dig2 = 0;

    let exp = /\.|\-|\//g;
    cnpj = cnpj.toString().replace(exp, "");
    let digito = parseInt((cnpj.charAt(12) + cnpj.charAt(13)), 10);

    for (let i = 0; i < valida.length; i++) {
      dig1 += (i > 0 ? (parseInt(cnpj.charAt(i - 1), 10) * valida[i]) : 0);
      dig2 += parseInt(cnpj.charAt(i), 10) * valida[i];
    }
    dig1 = (((dig1 % 11) < 2) ? 0 : (11 - (dig1 % 11)));
    dig2 = (((dig2 % 11) < 2) ? 0 : (11 - (dig2 % 11)));

    if (((dig1 * 10) + dig2) != digito) { return false; }
    return true;

  }

}
