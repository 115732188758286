import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import {Router} from "@angular/router";
import {JwtHelperService} from "@auth0/angular-jwt";

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private dateRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)$/;

  private utcDateRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/;

  constructor(private router: Router,
              private jwtHelper: JwtHelperService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf("viacep.com.br") > -1
        || request.url.indexOf("maps.googleapis.com") > -1) {
      return next.handle(request.clone({withCredentials: false})).do((event: HttpEvent<any>) => {
        return null;
      });
    }

    if (request.headers.get('No-Auth') === 'True'
        || request.url.endsWith('usuariosistema/auth')) {
      return next.handle(request.clone({withCredentials: true})).do((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.convertDates(event.body);
        }
        return null;
      });
    }

    if (localStorage.getItem('userToken') != null) {
      const clonedreq = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + localStorage.getItem('userToken')),
        withCredentials: true
      });
      return next.handle(clonedreq)
        .do(
          succ => {
            if (succ instanceof HttpResponse) {
              this.convertDates(succ.body);
            }
          },
          err => {
            if (err.status === 401) {
              localStorage.removeItem("userToken");
              this.router.navigateByUrl('/entrar');
            }
          }
        );
    } else {
      this.router.navigateByUrl('/entrar');
    }
  }

  private convertDates(object: Object) {
    if (!object || !(object instanceof Object)) {
      return;
    }

    if (object instanceof Array) {
      for (const item of object) {
        this.convertDates(item);
      }
    }

    for (const key of Object.keys(object)) {
      const value = object[key];

      if (value instanceof Array) {
        for (const item of value) {
          this.convertDates(item);
        }
      }

      if (value instanceof Object) {
        this.convertDates(value);
      }

      if (typeof value === 'string' && this.utcDateRegex.test(value)) {
        object[key] = new Date(value);
      }
    }
  }
}
