
export enum EnumTipoPromocao {
  NaoSelecionado = 'NaoSelecionado',
  Desconto = 'Desconto',
  Fidelidade = 'Fidelidade',
  Cupom = 'Cupom',
  Amostra = 'Amostra',
  Concurso = 'Concurso',
  Tabloid = 'Tabloid',
  CashBack = 'CashBack',
  L1P2 = 'L1P2',
  OfertaComum = 'OfertaComum',
  BeneficiosMercado = 'BeneficiosMercado',
}
