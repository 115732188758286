
export enum NotificacaoLayoutEnum {
  Default = 'Default',
  BigPicture = 'BigPicture',
  BigText = 'BigText',
  Inbox = 'Inbox',
  ProgressBar = 'ProgressBar',
  Messaging = 'Messaging',
  MessagingGroup = 'MessagingGroup',
  MediaPlayer = 'MediaPlayer',
}
