import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, pipe } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoaderService } from './loader.service';
// @Injectable({
//   providedIn: 'root'
// })
@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private loaderService: LoaderService) { }

  apisDash = ['dashstatusroteiro',
  'tempotrabalhadodash',
  'roteironodiadash',
  'fotosnodiadash',
  'roteirodiariodash',
  'listartempodeslocamento',
  'analiseexecucaodash',
  'abastecimentomarcasdash',
  'promotoresonlinedash',
  'rupturaspordiadash',
  'notificacao',
  'segmentoproduto',
  'timeline/localizacao',
  'timeline/dados',
  'timeline/listar',
  'timeline/roteiro',
  'checarsessao'];

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let reqUrl = req.url.toLowerCase();
    let contem = function(elem) {
      return reqUrl.includes(elem);
    };
    if (!this.apisDash.some(contem)) {
      this.showLoader();
    }
    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        if (!this.apisDash.some(contem)) {
          this.onEnd();
        }
      }
    },
      (err: any) => {
        if (!this.apisDash.some(contem)) {
          this.onEnd();
        }
    }));
  }
  private onEnd(): void {
    this.hideLoader();
  }
  private showLoader(): void {
    this.loaderService.show();
  }
  private hideLoader(): void {
    this.loaderService.hide();
  }
}
