import { TelasEnum } from "../../services-generated/models";

export interface Item {
    nome: string;
    valor: TelasEnum;
    caminho: string;
    habilitado: boolean;
}

export class GrupoTelas {
    grupo: string;
    nome: string;
    itens: Item[];
}

export const Telas = [
    {
        grupo: 'paginainicial',
        nome: 'Página inicial',
        itens: [
            {
                nome: 'Página inicial',
                caminho: '/home',
                valor: TelasEnum.PaginaInicial,
                habilitado: false
            },
            {
              nome: 'Dashboard',
              caminho: '/dashboard',
              valor: TelasEnum.Dashboard,
              habilitado: false
            },
            {
                nome: 'Dashboard BI',
                caminho: '/dashboard',
                valor: TelasEnum.PaginaInicialBi,
                habilitado: false
            }
        ]
    },
    {
        grupo: 'atualizacaocadastral',
        nome: 'Atualização cadastral',
        itens: [
            {
              nome: 'Atualização cadastral',
              caminho: '/atualizacao-cadastral',
              habilitado: false,
              valor: TelasEnum.AtualizacaoCadastral
            },
            {
                nome: 'Cadastrar',
                caminho: '/atualizacao-cadastral/cadastrar',
                habilitado: false,
                valor: TelasEnum.AtualizacaoCadastralCadastrar
            },
            {
                nome: 'Consultar relatório',
                caminho: '/atualizacao-cadastral/consultar',
                habilitado: false,
                valor: TelasEnum.AtualizacaoCadastralConsultar
            }
        ]
    },
    {
      grupo: 'valormarca',
      nome: 'Valor atendimento',
      itens: [
        {
          nome: 'Gerenciar valores',
          caminho: '/valor-marca',
          habilitado: false,
          valor: TelasEnum.ValorMarca
        }
      ]
    },
    {
      grupo: 'usuarios',
      nome: 'Usuários',
      itens: [
        {
          nome: 'Usuários',
          caminho: '/usuario-sistema',
          valor: TelasEnum.Usuarios,
          habilitado: false
        },
        {
          nome: 'Gerenciar usuários',
          caminho: '/usuario-sistema/consultar',
          valor: TelasEnum.UsuariosGerenciar,
          habilitado: false
        },
        {
          nome: 'Subir fichas para usuários',
          caminho: '/usuario-sistema/upload-ficha-cadastral',
          valor: TelasEnum.UsuariosSubirFicha,
          habilitado: false
        },
        {
          nome: 'Transferir subordinados',
          caminho: '/usuario-sistema/transferir-subordinado',
          valor: TelasEnum.UsuariosTransferirSubordinados,
          habilitado: false
        },
        {
            nome: 'Vinculo estabelecimentos-usuário',
            caminho: '/usuario-sistema/usuario-estabelecimento',
            valor: TelasEnum.UsuariosEstabelecimentos,
            habilitado: false
        },
        {
          nome: 'Candidatos',
          caminho: '/usuario-sistema/candidatos',
          valor: TelasEnum.UsuariosCandidatos,
          habilitado: false
        }
      ]
    },
    {
        grupo: 'formulariodinamico',
        nome: 'Formulário dinâmico',
        itens: [
            {
                nome: 'Formulários dinâmicos',
                valor: TelasEnum.FormulariosDinamicos,
                habilitado: false,
                caminho: '/formulario-dinamico/consultar'
            }
        ]
    },
    {
        grupo: 'tarefaspersonalizadas',
        nome: 'Tarefas personalizadas',
        itens: [
            {
                nome: 'Tarefas personalizadas',
                valor: TelasEnum.TarefasPersonalizadas,
                habilitado: false,
                caminho: '/tarefa-personalizada/consultar'
            }
        ]
    },
    {
        grupo: 'roteiros',
        nome: 'Roteiros',
        itens: [
            {
              nome: 'Roteiros',
              valor: TelasEnum.Roteiros,
              habilitado: false,
              caminho: '/roteiro'
            },
            {
                nome: 'Consultar roteiros',
                valor: TelasEnum.RoteirosConsultar,
                habilitado: false,
                caminho: '/roteiro/consultar'
            },
            {
                nome: 'Incluir sistemática roteiro',
                valor: TelasEnum.RoteirosIncluirSistematicaRoteiro,
                habilitado: false,
                caminho: '/roteiro/incluir-semanal'
            },
            {
                nome: 'Incluir sistemática fixo',
                valor: TelasEnum.RoteirosIncluirSistematicaFixo,
                habilitado: false,
                caminho: '/roteiro/incluir-semanal-fixo'
            },
            {
                nome: 'Finalizar por cliente',
                valor: TelasEnum.RoteirosFinalizarPorCliente,
                habilitado: false,
                caminho: '/roteiro/finalizar-roteiro'
            },
            {
                nome: 'Finalizar por promotor',
                valor: TelasEnum.RoteirosFinalizarPorPromotor,
                habilitado: false,
                caminho: '/roteiro/finalizar-roteiro-promotor'
            },
            {
                nome: 'Transferir roteiro',
                valor: TelasEnum.RoteirosTransferir,
                habilitado: false,
                caminho: '/roteiro/transferir-roteiros'
            },
            {
                nome: 'Incluir em massa',
                valor: TelasEnum.RoteirosIncluirEmMassa,
                habilitado: false,
                caminho: '/roteiro/incluir-em-massa-marca'
            },
            {
                nome: 'Roteirização automática',
                valor: TelasEnum.RoteirosAutomatico,
                habilitado: false,
                caminho: '/roteiro/roteiro-automatico'
            },
            {
                nome: 'Roteirização inteligente',
                valor: TelasEnum.RoteirosInteligente,
                habilitado: false,
                caminho: '/roteiro/roteiro-inteligente'
            },
            {
                nome: 'Importar roteiros',
                valor: TelasEnum.ImportarRoteiro,
                habilitado: false,
                caminho: '/roteiro/importar-roteiros'
            }
        ]
    },
    {
        grupo: 'timeline',
        nome: 'Timeline',
        itens: [
            {
                nome: 'Timeline',
                valor: TelasEnum.Timeline,
                habilitado: false,
                caminho: '/timeline/consultar'
            }
        ]
    },
    {
        grupo: 'orcamento',
        nome: 'Orçamento',
        itens: [
            {
              nome: 'Orçamentos',
              valor: TelasEnum.Orcamento,
              habilitado: false,
              caminho: '/orcamento'
            },
            {
                nome: 'Consultar orçamento',
                valor: TelasEnum.OrcamentoConsultar,
                habilitado: false,
                caminho: '/orcamento/consultar'
            },
            {
                nome: 'Cadastrar orçamento',
                valor: TelasEnum.OrcamentoUpload,
                habilitado: false,
                caminho: '/orcamento/upload'
            },
            {
                nome: 'Gerar rota',
                valor: TelasEnum.OrcamentoGerarRota,
                habilitado: false,
                caminho: '/orcamento/gerar-rota'
            }
        ]
    },
    {
        grupo: 'albunsdefotos',
        nome: 'Álbuns de fotos',
        itens: [
            {
                nome: 'Álbuns de fotos',
                valor: TelasEnum.AlbunsDeFotos,
                habilitado: false,
                caminho: '/album-fotos/consultar'
            }
        ]
    },
    {
        grupo: 'cartas',
        nome: 'Cartas',
        itens: [
            {
              nome: 'Cartas',
              valor: TelasEnum.Cartas,
              habilitado: false,
              caminho: '/cartas'
            },
            {
                nome: 'Criar cartas',
                valor: TelasEnum.CartasCriar,
                habilitado: false,
                caminho: '/cartas/gerar'
            },
            {
                nome: 'Gerenciar empresas',
                valor: TelasEnum.CartasGerenciarEmpresas,
                habilitado: false,
                caminho: '/empresa'
            },
            {
                nome: 'Modelos de cartas',
                valor: TelasEnum.CartasModelos,
                habilitado: false,
                caminho: '/cartas/modelo'
            }
        ]
    },
    {
        grupo: 'estabelecimentos',
        nome: 'Estabelecimentos',
        itens: [
            {
                nome: 'Redes',
                valor: TelasEnum.EstabelecimentosRedes,
                habilitado: false,
                caminho: '/rede-estabelecimentos/consultar'
            },
            {
                nome: 'Bandeiras',
                valor: TelasEnum.EstabelecimentosBandeiras,
                habilitado: false,
                caminho: '/bandeira/consultar'
            },
            {
                nome: 'Gerenciamento de estabelecimentos',
                valor: TelasEnum.EstabelecimentosEstabelecimentos,
                habilitado: false,
                caminho: '/estabelecimento/consultar'
            },
            {
                nome: 'Subcanais',
                valor: TelasEnum.EstabelecimentosSubcanais,
                habilitado: false,
                caminho: '/subcanal/consultar'
            }
        ]
    },
    {
        grupo: 'feriado',
        nome: 'Feriado',
        itens: [
            {
                nome: 'Feriado',
                valor: TelasEnum.Feriado,
                habilitado: false,
                caminho: '/feriado/consultar'
            }
        ]
    },
    {
        grupo: 'produtos',
        nome: 'Produtos',
        itens: [
            {
                nome: 'Categorias',
                valor: TelasEnum.ProdutosCategorias,
                habilitado: false,
                caminho: '/categoria-produto/consultar'
            },
            {
                nome: 'Subcategorias',
                valor: TelasEnum.ProdutosSubcategorias,
                habilitado: false,
                caminho: '/subcategoria-produto/consultar'
            },
            {
                nome: 'Segmentos',
                valor: TelasEnum.ProdutosSegmentos,
                habilitado: false,
                caminho: '/segmento-produto/consultar'
            },
            {
                nome: 'Marcas',
                valor: TelasEnum.ProdutosMarcas,
                habilitado: false,
                caminho: '/marca/consultar'
            },
            {
                nome: 'Famílias',
                valor: TelasEnum.ProdutosFamilias,
                habilitado: false,
                caminho: '/familia-produtos/consultar'
            },
            {
                nome: 'Gerenciamento de produtos',
                valor: TelasEnum.ProdutosProdutos,
                habilitado: false,
                caminho: '/produto/consultar'
            },
            {
                nome: 'Vincular produtos',
                valor: TelasEnum.ProdutosVincular,
                habilitado: false,
                caminho: '/produto-estabelecimento/vincular'
            },
            {
                nome: 'Vincular produtos por bandeira',
                valor: TelasEnum.ProdutosVincularPorBandeira,
                habilitado: false,
                caminho: '/produto-estabelecimento/vincular-multiplos'
            },
            {
                nome: 'Vincular produtos por zona',
                valor: TelasEnum.ProdutosVincularPorZona,
                habilitado: false,
                caminho: '/produto-estabelecimento/vincular-multiplos-zona'
            }
        ]
    },
    {
        grupo: 'relatorios',
        nome: 'Relatórios',
        itens: [
            {
                nome: 'Dados coletados',
                valor: TelasEnum.RelatoriosDadosColetados,
                habilitado: false,
                caminho: '/relatorios/dados-coletados'
            },
            {
                nome: 'Produtos em ruptura',
                valor: TelasEnum.RelatoriosProdutosEmRuptura,
                habilitado: false,
                caminho: '/relatorios/ruptura-e-prod-disponivel'
            },
            {
                nome: 'Logs de dispositivos',
                valor: TelasEnum.RelatoriosLogsDispositivos,
                habilitado: false,
                caminho: '/relatorios/logs-dispositivos'
            },
            {
                nome: 'Logs de geolocalização',
                valor: TelasEnum.RelatoriosLogsGeolocalizacao,
                habilitado: false,
                caminho: '/relatorios/logs-geolocalizacao'
            },
            {
                nome: 'Registro de ponto analítico',
                valor: TelasEnum.RelatoriosRegistroPontoAnalitico,
                habilitado: false,
                caminho: '/relatorios/registro-de-ponto'
            },
            {
                nome: 'Registro de ponto sintético',
                valor: TelasEnum.RelatoriosRegistroPontoSintetico,
                habilitado: false,
                caminho: '/relatorios/registro-de-ponto-sintetico'
            },
            {
                nome: 'Registro de ausência',
                valor: TelasEnum.RelatoriosRegistroAusencia,
                habilitado: false,
                caminho: '/relatorios/ausentes'
            },
            {
                nome: 'Registro de ausência express',
                valor: TelasEnum.RelatoriosRegistroAusenciaExpress,
                habilitado: false,
                caminho: '/relatorios/express-ausentes'
            },
            {
                nome: 'Monitoramento de operação',
                valor: TelasEnum.RelatoriosMonitoramentoOperacao,
                habilitado: false,
                caminho: '/relatorios/painel-status-usuarios'
            },
            {
                nome: 'Horas por marca',
                valor: TelasEnum.RelatoriosHorasPorMarca,
                habilitado: false,
                caminho: '/relatorios/hora-por-marca'
            },
            {
                nome: 'Tempo em deslocamento',
                valor: TelasEnum.RelatoriosTempoDeslocamento,
                habilitado: false,
                caminho: '/relatorios/tempo-em-deslocamento'
            },
            {
                nome: 'Horas extras',
                valor: TelasEnum.RelatoriosHorasExtras,
                habilitado: false,
                caminho: '/relatorios/horas-extras'
            },
            {
                nome: 'Questionários',
                valor: TelasEnum.RelatoriosQuestionarios,
                habilitado: false,
                caminho: '/relatorios/questionarios'
            },
            {
                nome: 'Clientes por estabelecimento',
                valor: TelasEnum.RelatoriosClientesPorEstabelecimento,
                habilitado: false,
                caminho: '/relatorios/marca-estabelecimentos'
            },
            {
                nome: 'Sistemática por cliente',
                valor: TelasEnum.RelatoriosSistematicaPorCliente,
                habilitado: false,
                caminho: '/relatorios/marca-promotores'
            },
            {
                nome: 'Sem roteiro',
                valor: TelasEnum.RelatoriosSemRoteiro,
                habilitado: false,
                caminho: '/relatorios/sem-roteiro'
            },
            {
                nome: 'Descontos',
                valor: TelasEnum.RelatoriosDescontos,
                habilitado: false,
                caminho: '/relatorios/descontos'
            },
            {
                nome: 'EPI',
                valor: TelasEnum.RelatoriosEPI,
                habilitado: false,
                caminho: '/relatorios/epi'
            },
            {
                nome: 'Justificativa de ponto',
                valor: TelasEnum.RelatoriosJustificativaPonto,
                habilitado: false,
                caminho: '/relatorios/foto-registro-ponto-atestado'
            },
            {
                nome: 'TarefasPlanejadas',
                valor: TelasEnum.RelatoriosPlanejados,
                habilitado: false,
                caminho: '/relatorios/tarefas-planejadas'
            },
            {
                nome: 'Balanceamento',
                valor: TelasEnum.RelatoriosBalanceamento,
                habilitado: false,
                caminho: '/relatorios/atendimento-estabelecimento'
            },
            {
                nome: 'Tempo de execução',
                valor: TelasEnum.RelatoriosTempoExecucao,
                habilitado: false,
                caminho: '/relatorios/tempo-execucao'
            }
        ]
    },
    {
        grupo: 'relatoriosgerenciais',
        nome: 'Relatórios gerenciais',
        itens: [
            {
                nome: 'Análise de execução',
                valor: TelasEnum.RelatoriosGerenciaisAnaliseExecucao,
                habilitado: false,
                caminho: '/relatorios/analise-execucao'
            },
            {
                nome: 'Performance',
                valor: TelasEnum.RelatoriosGerenciaisPerformance,
                habilitado: false,
                caminho: '/relatorios/balanceamento'
            },
            {
                nome: 'Performance de fotos',
                valor: TelasEnum.RelatoriosGerenciaisPerformanceFotos,
                habilitado: false,
                caminho: '/relatorios/performance-fotos-geral'
            },
            {
                nome: 'Performance por cliente',
                valor: TelasEnum.RelatoriosGerenciaisPerformancePorCliente,
                habilitado: false,
                caminho: '/relatorios/performance-marca'
            },
            {
                nome: 'Performance de fotos por cliente',
                valor: TelasEnum.RelatoriosGerenciaisPerformanceFotosPorCliente,
                habilitado: false,
                caminho: '/relatorios/performance-fotos-cliente'
            },
            {
                nome: 'Exportar matriz',
                valor: TelasEnum.RelatoriosGerenciaisExportarMatriz,
                habilitado: false,
                caminho: '/roteiro/exportar-matriz'
            },
            {
                nome: 'Relação produtos lojas',
                valor: TelasEnum.RelatoriosGerenciaisRelacaoProdutosLojas,
                habilitado: false,
                caminho: '/relatorios/produtos-lojas'
            },
            {
              nome: 'Demonstrativo Express',
              valor: TelasEnum.RelatoriosGerenciaisDemonstrativoExpress,
              habilitado: false,
              caminho: '/relatorios/demonstrativo-express'
            },
            {
              nome: 'Usuários Ativos',
              valor: TelasEnum.RelatoriosGerenciaisUsuariosAtivos,
              habilitado: false,
              caminho: '/relatorios/usuarios-ativos'
            }
        ]
    },
    {
        grupo: 'documentos',
        nome: 'Documentos',
        itens: [
            {
              nome: 'Documentos',
              valor: TelasEnum.Documentos,
              habilitado: false,
              caminho: '/holerite'
            },
            {
                nome: 'Holerites',
                valor: TelasEnum.DocumentosBaixarHolerite,
                habilitado: false,
                caminho: '/holerite'
            },
            {
                nome: 'PLR',
                valor: TelasEnum.DocumentosPLR,
                habilitado: false,
                caminho: '/holerite/plr'
            },
            {
                nome: 'Baixar holerite',
                valor: TelasEnum.DocumentosBaixarHolerite,
                habilitado: false,
                caminho: '/holerite/baixar'
            },
            {
                nome: 'Upload de holerite',
                valor: TelasEnum.DocumentosUploadHolerite,
                habilitado: false,
                caminho: '/holerite/upload'
            },
            {
                nome: 'Informes de rendimentos',
                valor: TelasEnum.DocumentosInformeRendimentos,
                habilitado: false,
                caminho: '/holerite/rendimento'
            }
        ]
    },
    {
        grupo: 'mei',
        nome: 'MEI',
        itens: [
            {
                nome: 'Incluir pagamento',
                valor: TelasEnum.MeiIncluirPagamento,
                hanilitado: false,
                caminho: '/mei/cadastrar-pagamento'
            }
        ]
    },
    {
        grupo: 'subirfoto',
        nome: 'Subir foto',
        itens: [
            {
                nome: 'Subir foto',
                valor: TelasEnum.SubirFotoSubirFoto,
                habilitado: false,
                caminho: '/subir-foto/upload'
            }
        ]
    },
    // {
    //     grupo: 'notificacaomobile',
    //     nome: 'Notificação Mobile',
    //     itens: [
    //         {
    //             nome: 'Incluir',
    //             valor: TelasEnum.NotificacaoMobileIncluir,
    //             habilitado: false,
    //             caminho: '/notificacao-mobile/incluir'
    //         }
    //     ]
    // }
] as GrupoTelas[];
