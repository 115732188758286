
export enum TipoMarcacaoEnum {
  Entrada = 'Entrada',
  InicioAlmoco = 'InicioAlmoco',
  InicioAlmocoSistema = 'InicioAlmocoSistema',
  FimAlmoco = 'FimAlmoco',
  FimAlmocoSistema = 'FimAlmocoSistema',
  Saida = 'Saida',
  SaidaPeloSistema = 'SaidaPeloSistema',
  Justificado = 'Justificado',
  Abono = 'Abono',
  AbonoHora = 'AbonoHora',
  Desconto = 'Desconto',
  DescontoHora = 'DescontoHora',
}
