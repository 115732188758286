import { Pipe, PipeTransform } from '@angular/core';
import { StatusRoteiroExecutadoEnum } from '../../services-generated/models/statusroteiroexecutadoenum.model';

@Pipe({
  name: 'statusRoteiroExecEnum'
})
export class StatusRoteiroExecEnumPipe implements PipeTransform {

  transform(value: any, ): any {
    switch (value) {
      case StatusRoteiroExecutadoEnum.Iniciado:
        return 'Iniciado';
      case StatusRoteiroExecutadoEnum.NaoIniciado:
        return 'Não iniciado';
      case StatusRoteiroExecutadoEnum.FinalizadoPorNaoVisita:
        return 'Finalizado por não visita';
      case StatusRoteiroExecutadoEnum.FinalizadoPorCheckout:
        return 'Finalizado por checkout';
      case StatusRoteiroExecutadoEnum.FinalizadoPeloSupervisor:
        return 'Finalizado pelo supervisor';
      default:
        return 'Inválido';
    }
  }

}
