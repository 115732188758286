import { Pipe, PipeTransform } from '@angular/core';
import { moment } from "ngx-bootstrap/chronos/test/chain";

@Pipe({
  name: 'engTimespan',
  pure: false
})
export class EngTimespan implements PipeTransform {

  constructor() {

  }

  transform(phrase: any, pipeArgs: any): any {
    if (!phrase || phrase.lenght == 0) return "";
    if (phrase[0] == '-') return "";
    var originalMask = "HH:mm:ss.SSSSSSSSSS".substring(0, phrase.length);
    var m = moment(phrase, originalMask, true);
    var result = m.format(pipeArgs);
    return result;
  }
}
